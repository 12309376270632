<template>
  <teleport to="body">
    <div v-if="open" class="modal" @click.self.prevent="open = ! open">
      <div class="wrapper">

        <div class="content">
          <h3 class="title">{{ title }}</h3>

          <label>{{ message }}</label>
          <input type="text" v-model="result" />

        </div>

        <div class="actions">
          <button class="action cancel cancel-text" @click.prevent.stop="cancel">Cancel</button>
          <button class="action primary" @click.prevent.stop="commit">{{ primary }}</button>
        </div>

      </div>

    </div>
  </teleport>
</template>

<script>

export default {
  name: 'SingleInput',
  props: ['show', 'title', 'message', 'primary', 'placeholder'],
  emits: ['save'],
  data() {
    return {
      open: false,
      result: null
    }
  },
  methods: {
    cancel() {
      this.open = ! this.open;
    },
    commit() {
      this.$emit('save', this.result);
      this.open = false;
    },
  },
  watch: {
    show() {
      this.open = true;
    }
  }
}

</script>

<style lang="scss" scoped>
@import "public/wrapper";

</style>
